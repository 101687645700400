<template>
    <div class="container py-4 pb-5 px-2 blog-container">
        <h3 class="mb-3 text-center">{{ blog.title }}</h3>
        <div class="text-center">
            <div class="small">Posted - {{ new Date(blog.created_at).toDateString() }}</div>
            <div class="small">By - {{ blog.user.name }}</div>
        </div>
        <p class="my-4" v-html="blog.description" />
    </div>
</template>

<script>
import scrollTop from "../utils/scrollTop"
import request from "../../apis/request"

export default {
    async mounted() {
        scrollTop()
        await this.fetchBlog()
    },
    data() {
        return {
            blog: null,
            blogId: this.$route.params.id,
        }
    },
    methods: {
        async fetchBlog() {
            try {
                const res = await request.get("/blog/" + this.blogId)
                this.blog = res.data && res.data.result
            } catch (err) {
                console.log("error in fetching blog")
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.blog-container {
    margin: 0 auto;
    max-width: 800px;
}
</style>
